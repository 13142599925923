const For = {}

const language = document.documentElement.lang;

/* GENERAL QUERY STRUCTURE FOR IMAGES */
For.Images = {
  query: 'page.images',
  select: {
    id: true,
    content: language ? 'file.content("' + language + '")' : true,
    filename: true,
    url: true,
    width: 'file.width()',
    height: 'file.height()',
    orientation: 'file.orientation()',
    ratio: 'file.ratio()',
    srcset: 'file.srcset([250, 450, 800, 1440])',
    /* webp: 'file.srcset("webp")', */
    /* avif: 'file.srcset("avif")' */
  }
};

/* QUERY STRUCTURE FOR DOCUMENTS */
For.Files = {
  query: 'page.files',
  select: {
    id: true,
    filename: true,
    url: true
  }
};

/* BASE FIELDS FOR PAGES */
const Base = {
  title: language ? 'page.content("' + language + '").title' : true,
  content: language ? 'page.content("' + language + '")' : true,
  url: language ? 'page.url("' + language + '")' : true,
  id: true,
  path: language ? 'page.path("' + language + '")' : 'page.path()',
  parenturl: language ? 'page.parentUrl("' + language + '")' :  'page.parentUrl()',
  parenttitle: language ? 'page.parentTitle("' + language + '")' :  'page.parentTitle()',
  uid: true,
  template: 'page.intendedTemplate()',
  listed: 'page.isListed',
  files: For.Files,
  images: For.Images,
  children: {
    query: 'page.children',
    select: {
      id: true,
      uid: true,
      title: language ? 'page.content("' + language + '").title' : true,
      //type: language ? 'page.content("' + language + '")' : true,
      //content: language ? 'page.content("' + language + '")' : true,
      url: language ? 'page.url("' + language + '")' : true,
      path: language ? 'page.path("' + language + '")' : 'page.path()',
    }
  }
};

/* GENERAL QUERY STRUCTURE FOR CHILDREN */
For.Children = {
  ...Base,
  children: {
    query: 'page.children',
    select: {
      ...Base,
    }
  }
};

/* PAGES */
/* ===== */

For.Default = {
  ...Base,
  text: language ? 'page.content("' + language + '").text.kirbytext' : 'page.text.kirbytext',
}

For.Home = {
  ...Base,
  featured: true,
  more: true,
}

/* BASE FIELDS FOR PAGES */
For.Issue = {
  title: language ? 'page.content("' + language + '").title' : true,
  content: language ? 'page.content("' + language + '")' : true,
  url: language ? 'page.url("' + language + '")' : true,
  id: true,
  path: language ? 'page.path("' + language + '")' : 'page.path()',
  parent: true,
  next: true,
  prev: true,
  //uuid: 'page.uuid',
  uid: true,
  template: 'page.intendedTemplate()',
  listed: 'page.isListed',
  files: For.Files,
  images: For.Images,
  children: {
    query: 'page.children',
    select: {
      id: true,
      uid: true,
      title: language ? 'page.content("' + language + '").title' : true,
      content: language ? 'page.content("' + language + '")' : true,
      url: language ? 'page.url("' + language + '")' : true,
      path: language ? 'page.path("' + language + '")' : 'page.path()',
    }
  }
};

/* BASE FIELDS FOR PAGES */
For.Article = {
  title: language ? 'page.content("' + language + '").title' : true,
  content: language ? 'page.content("' + language + '")' : true,
  url: language ? 'page.url("' + language + '")' : true,
  id: true,
  path: language ? 'page.path("' + language + '")' : 'page.path()',
  parent: true,
  next: true,
  prev: true,
  //uuid: 'page.uuid',
  uid: true,
  template: 'page.intendedTemplate()',
  listed: 'page.isListed',
  files: For.Files,
  images: For.Images,
  children: {
    query: 'page.children',
    select: {
      id: true,
      uid: true,
      title: language ? 'page.content("' + language + '").title' : true,
      content: language ? 'page.content("' + language + '")' : true,
      url: language ? 'page.url("' + language + '")' : true,
      path: language ? 'page.path("' + language + '")' : 'page.path()',
    }
  }
};

/* BASE FIELDS FOR PAGES */
For.Subpage = {
  title: language ? 'page.content("' + language + '").title' : true,
  content: language ? 'page.content("' + language + '")' : true,
  url: language ? 'page.url("' + language + '")' : true,
  id: true,
  path: language ? 'page.path("' + language + '")' : 'page.path()',
  parent: true,
  next: true,
  prev: true,
  //uuid: 'page.uuid',
  uid: true,
  template: 'page.intendedTemplate()',
  listed: 'page.isListed',
  files: For.Files,
  images: For.Images,
  children: {
    query: 'page.children',
    select: {
      id: true,
      uid: true,
      title: language ? 'page.content("' + language + '").title' : true,
      content: language ? 'page.content("' + language + '")' : true,
      url: language ? 'page.url("' + language + '")' : true,
      path: language ? 'page.path("' + language + '")' : 'page.path()',
    }
  }
};

For.Site = {
  title: language ? 'site.content("' + language + '").title' : true,
  content: language ? 'site.content("' + language + '")' : true,
  images: {
    query: 'site.images',
    select: {
      id: true,
      content: language ? 'file.content("' + language + '")' : true,
      filename: true,
      url: true,
      width: 'file.width()',
      height: 'file.height()',
      orientation: 'file.orientation()',
      ratio: 'file.ratio()',
      srcset: 'file.srcset([250, 450, 800, 1440])',
      /* webp: 'file.srcset("webp")', */
      /* avif: 'file.srcset("avif")' */
    }
  }
}

export default {
  For
}
