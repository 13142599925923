import './index.css'
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { router } from './router.js'
import App from './App.vue'
import VueSmoothScroll from 'vue3-smooth-scroll'
import 'vue-fullpage.js/dist/style.css'
import VueFullPage from 'vue-fullpage.js'

//Constants
const pinia = createPinia()
const app = createApp(App)

//Use
app.use(pinia)
app.use(router)
app.use(VueFullPage)
app.use(VueSmoothScroll)

//Mount
app.mount('#app')
