import { ref, watch } from 'vue';

/* Refs */
export const mode =  ref('dark');
export const backButtonVisible =  ref(0);
export const fixedHeader =  ref(false);

/* Utils */
document.body.classList.add(mode.value);

/* Watchers */
watch(mode, (newMode, oldMode) => document.body.classList.replace(oldMode, newMode));

/* Export--*/
export function composables() {
  return {
    mode,
    backButtonVisible,
    fixedHeader,
  };
}
