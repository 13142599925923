// stores/counter.js
import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import yaml from 'js-yaml';
import { cleanUpJSON } from './helpers.js'
import axios from 'axios';
import For from './KQLSelects.js';
const select = For;

const GetByKey = (customKey = 'uid', objects) => {
  let returnedObjects = [];
  Object.keys(objects).map((key)=>{
    if(!objects[key][customKey]) return false;
    returnedObjects[objects[key][customKey]] = objects[key]
  });
  return returnedObjects;
};

const ArrayOfPageUIDsFromPath = function(path = 'home') {
  path = path.replace(/^\/|\/$/g, "");
  let pages = !path ? ['home'] : path.split('/');
  let language = 'en';
  if(pages[0] == document.documentElement.lang) language = pages.shift();
  return (pages.length == 0) ? ['home'] : pages;
}

const KQLQueryStringFromArrayOfParentIDs = function(pages = ['home']) {
  let queryString = 'page("'+pages.shift()+'")';
  while (pages.length) {
    queryString = queryString + '.children.find("'+pages.shift()+'")'
  }
  //console.log('HERE!', queryString);
  return queryString;
}

export const useStore = defineStore('kirby', () => {

  /* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
  /* !State */
  /* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

  const loading = ref(0);
  const site = ref({});
  const rawPage = ref({});
  const rawPages = ref({});
  const api = "/api/cachedKQL"; //api: "/api/query"

  /* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
  /* !State with added methods */
  /* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

  const page = computed(()=>{
      let modifiedPage = rawPage.value;
      if(modifiedPage.content) modifiedPage.content.yaml = (field)=> yaml.load(modifiedPage.content[field]);
      if(modifiedPage.content) modifiedPage.content.lineofyaml = (field)=> yaml.load(modifiedPage.content[field])[0].trim();
      if(modifiedPage.images) modifiedPage.images.byKey = (customKey = 'id') => {return GetByKey(customKey, modifiedPage.images)};
      return modifiedPage;
  });

  const pages = computed(()=>{
      let modifiedPages = rawPages.value;
      modifiedPages.byKey = (customKey = 'uid') => {return GetByKey(customKey, rawPages.value)};
      return modifiedPages;
  });

  /* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
  /* !Methods: loadPageAndSite */
  /* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

  //Load pages and site. Usually only once
  const loadPageAndSite = (path = 'home', template) => {
    loading.value += 1;
    const pageUIDs = ArrayOfPageUIDsFromPath(path);
    const queryString = KQLQueryStringFromArrayOfParentIDs(pageUIDs);
    const query = {
      select: {
        site: {
          query: 'site',
          select: select.For.Site,
        },
        pages: {
          query: 'site.children',
          select: select.For.Children
        }
      }
    };
      //Load
    axios.post(api, query).then((response) => {
      if(!response.data.result.pages.length) throw new Error("Kirby API is not loading or does not have pages to load.");
      if(!response.data.result.pages.length) console.error("Kirby API is not loading or does not have pages to load.");
      if(!response.data.result.pages.length) throw new TypeError("Kirby API is not loading or does not have pages to load.");
      loading.value -= 1;
      site.value = cleanUpJSON(response.data.result.site);
      rawPages.value = cleanUpJSON(response.data.result.pages);
    });
  }

  /* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
  /* !Methods: loadPage*/
  /* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

  //Load current page
  const loadPage = (path = 'home', template) => {
    loading.value += 1;
    const pages = ArrayOfPageUIDsFromPath(path);
    const queryString = KQLQueryStringFromArrayOfParentIDs(pages);
    //console.log(template, select.For[template]);
    const query = {
      select: {
        page: {
          query: queryString,
          select: select.For[template] || select.For.Default
        }
      }
    }
    //Load
    axios.post(api, query).then((response) => {
      if(!response.data.result.page) throw new Error("Kirby API is not loading or does not have pages to load.");
      loading.value -= 1;
      rawPage.value = cleanUpJSON(response.data.result.page);
    });
  }

  /* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
  /* !Return outside */
  /* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
  return {loading, site, pages, page, loadPageAndSite, loadPage};
});
